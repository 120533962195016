import "./styles.css";

const Footer = () => {
    return (
        <div className="container-fluid footer">
            <div className="row p-4">
                <div className="col-sm-3">
                    <h5 className="">Services</h5>
                    Platform as a Service (PaaS)<br />
                    Software as a Service (SaaS)<br />
                    AMC
                </div>
                <div className="col-sm-3">
                    <h5 className="">Products</h5>
                    Hospital Management<br />
                    Diagnostic Management<br />
                    School Management
                </div>
                <div className="col-sm-3">
                    <h5 className="">About us</h5>
                    About us<br />
                    Vision<br />
                    Mission
                </div>
                <div className="col-sm-3">
                    <h5 className="">Contact</h5>
                    Cell : 8861012023<br />
                    Email : pshagk@gmail.com<br />
                    APPA Infotech Services Pvt. Ltd.<br />
                    Banashankari 1st Stage<br />
                    BENGALURU - 50
                </div>
            </div>
            <div className="row">
                <div className="copyright">Copyright 2023, APPA Infotech Services Pvt. Ltd, Bangalore, All rights reserved</div>
            </div>
        </div>
    );
}

export default Footer;