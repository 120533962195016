import openAxios from './OpenAxios';
import authAxios from './AuthAxios';

const getProperty = async (name) => {
    const res = await openAxios.get(`/portal/property?name=${name}`);
    return res.data;
}
const getMenuData = async (type) => {
    const res = await openAxios.get(`/portal/menu?type=${type}`);
    return res.data;
}

//Without pagination
const getList = async (tableName, selectClause, whereClause, orderBy, sortOrder, view) => {
    const res = await authAxios.post("/portal/list", {
        "tableName": tableName,
        "selectClause": selectClause,
        "orderBy": orderBy,
        "sortOrder": sortOrder,
        "whereClause": whereClause,
        "view": view
    });
    return res.data;
}

const getSingleOrFirst = async (table, column, value) => {
    const res = await authAxios.get(`/portal/sorf?table=${table}&column=${column}&value=${value}`);
    return res.data;
}

const getChoices = async (tableName, field) => {
    const res = await authAxios.get(`/auxin/choices?tableName=${tableName}&field=${field}`);
    return res.data;
}
const getById = async (viewId, table, id) => {
    const res = await authAxios.get(`/auxin/byId?viewId=${viewId}&tableName=${table}&id=${id}`);
    return res.data;
}
const getByName = async (table, name) => {
    const res = await authAxios.get(`/auxin/byName?tableName=${table}&name=${name}`);
    return res.data;
}
// give the table name and column name and the column value
const getByColumnValue = async (table, column, value) => {
    const res = await authAxios.get(`/auxin/byColumnValue?tableName=${table}&column=${column}&value=${value}`);
    return res.data;
}
const getNextId = async (table) => {
    const res = await authAxios.get(`/auxin/nextid?table=${table}`);
    return res.data;
}

const getListColumns = async (view, tableName) => {
    const res = await authAxios.get(`/auxin/columns?viewId=${view}&tableName=${tableName}`);
    return res.data;
}
const getUIActions = async (tableName, type) => {
    const res = await authAxios.get(`/auxin/uiactions?tableName=${tableName}&type=${type}`);
    return res.data;
}
const getRelatedLists = async (table) => {
    const res = await authAxios.get(`/auxin/relatedlists?tableName=${table}`);
    return res.data;
}
const getData = async (table, Id, selectClause) => {
    const res = await authAxios.get(`/auxin/data?tableName=${table}&id=${Id}&selectClause=${selectClause}`);
    return res.data;
}
const getFormData = async (view, table, Id) => {
    const res = await authAxios.get(`/auxin/formData?viewId=${view}&tableName=${table}&id=${Id}`);
    return res.data;
}
const getOperators = async (dataType) => {
    const res = await authAxios.get(`/auxin/operators?dataType=${dataType}`);
    return res.data;
}

const postExternal = async (endpoint, token, body) => {
    const res = await authAxios.post(endpoint, body, { headers: { "Authorization": `Bearer ${token}` } });
    return res.data;
}
const executeServerScipt = async (script) => {
    const res = await authAxios.post("/auxin/serverscriptpad", { "script": script });
    return res.data;
}

const executeClassScipt = async (className, methodName, argsList) => {
    var temp = {
        "className": className,
        "methodName": methodName,
        "argsList": argsList
    }
    console.log(temp);
    const res = await authAxios.post("/auxin/classscript", temp);
    return res.data;
}

//with pagination
const getListData = async (tableName, whereClause, orderBy, sortOrder, pageNumber, pageSize) => {
    const res = await authAxios.post("/auxin/listwp", {
        "tableName": tableName,
        "orderBy": orderBy,
        "sortOrder": sortOrder,
        "whereClause": whereClause,
        "pageNumber": pageNumber,
        "pageSize": pageSize,
        "view": 1
    });
    return res.data;
}
const extractData = async ({ table, input }) => {
    const res = await authAxios.post("/auxin/extract", {
        "table": table,
        "input": JSON.stringify(input),
    });
    return res.data;
}
const insertData = async ({ table, input }) => {
    const res = await authAxios.post("/auxin/insert", {
        "table": table,
        "input": JSON.stringify(input),
    });
    return res.data;
}

const updateData = async ({ table, id, input }) => {
    const res = await authAxios.post("/auxin/update", {
        "table": table,
        "id": id,
        "input": JSON.stringify(input),
    });
    return res.data;
}

//input required for onDelete server rule
const deleteData = async ({ table, id, input }) => {
    const res = await authAxios.post("/auxin/delete", {
        "table": table,
        "id": id,
        "input": JSON.stringify(input),
    });
    return res.data;
}


const updateProperties = async (data) => {
    const res = await authAxios.post("/auxin/updateProperties", data);
    return res.data;
}

const getToken = async (username, password) => {
    const res = await authAxios.post("/auth/token", { username, password });
    return res.data;
}

const getProfile = async () => {
    const res = await authAxios.get("auth/users/profile");
    return res.data;
}

const gs = {
    getToken,
    getProfile,
    executeServerScipt,
    executeClassScipt,
    getListColumns,
    getMenuData,
    getListData,
    getList,
    getData,
    getFormData,
    getOperators,
    getProperty,
    getById,
    getByName,
    getByColumnValue,
    updateProperties,
    insertData,
    updateData,
    getUIActions,
    getRelatedLists,
    getSingleOrFirst,
    getNextId,
    deleteData,
    extractData,
    getChoices,
    postExternal,
}

export default gs;