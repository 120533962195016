import React from 'react'
import AuthLogin from './AuthLogin'
import AuthFooter from './AuthFooter'

function AuthWrapper() {
    return (
        <div>
            <AuthLogin />
            <AuthFooter />
        </div>
    )
}

export default AuthWrapper