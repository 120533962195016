import { Outlet } from 'react-router-dom';
import Header from './Header';
import Footer from '../footer';

const LayoutPortal = () => {
  return (
    <div className=''>
      <Header />
      <div className="content" style={{ flexGrow: 1 }}>
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};

export default LayoutPortal;