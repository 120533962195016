import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import gs from "../services/gs";
import Carousel from "../components/carousel";
import Announcement from "../components/announcement";
import QuickLinks from "../components/quick-links";
import ContentBlock from "../components/content-block";

const Page = () => {
    const { page } = useParams();
    const [pageData, setPageData] = useState();

    useEffect(() => {
        // gs.getByColumnValue('app_portal', 'url_suffix', page).then(response => {
        //     console.log(response);
        //     setPageData(response);
        // })
    }, [page])

    return (
        <div>
            <Carousel width="100%" height="450px" />
            <Announcement />
            <QuickLinks />
            <ContentBlock value="1" bgcolor="#f9f9f9"/>
            <ContentBlock value="2"  />
        </div>
    )
}

export default Page;