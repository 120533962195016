import { Link } from "react-router-dom";
import "./styles.css";

const Card = ({ data }) => {
    return (
        <Link className="col card" to={data.url}>
            <div className="card-body">
                <img src={data.image_url} className="imgBox" alt="..." />
                <h5 className="title" dangerouslySetInnerHTML={{ __html: data.title }}></h5>
                <p className="details">{data.description}</p>
            </div>
        </Link>
    );
};

export default Card;
