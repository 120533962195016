import { toast } from "react-toastify";

const useGForm = (sections) => {

    const getValue = (name) => {
        for (const section of sections) {
            if (section.elements) {
                const child = section.elements.find((child) => child.column.name === name);
                if (child) {
                    return child.column.data;
                }
            } else {
                const child = sections.find((child) => child.name === name);
                if (child) {
                    return child.data;
                }
            }
        }
        return '';
    }

    const getLabel = (name) => {
        for (const section of sections) {
            if (section.elements) {
                const child = section.elements.find((child) => child.column.name === name);
                if (child) {
                    return child.column.label;
                }
            } else {
                const child = sections.find((child) => child.name === name);
                if (child) {
                    return child.label;
                }
            }
        }
        return '';
    }
    
    const getData = (name) => {
        for (const section of sections) {
            if (section.elements) {
                const child = section.elements.find((child) => child.column.name === name);
                if (child) {
                    return child.column;
                }
            } else {
                const child = sections.find((child) => child.name === name);
                if (child) {
                    return child;
                }
            }
        }
        return '';
    }

    const getScriptValue = (name) => {
        for (const section of sections) {
            if (section.elements) {
                const child = section.elements.find((child) => child.column.name === name);
                if (child) {
                    console.log(child.column.data);
                    return child.column.data.replace(/<[^>]*>/g, '').replace(/&gt;/g, '>');
                }
            }
        }
        return '';
    }

    const getAll = () => {
        var maping = [];
        if (sections) {
            for (const section of sections) {
                if (section.elements) {
                    for (const element of section.elements) {
                        if (element.column) {
                            maping = maping.concat({ [element.column.name]: element.column.data });
                        }
                    }
                }
            }
        }
        var newObj = Object.assign({}, ...maping);
        return newObj;
    }

    const getRefData = (field, refData) => {
        refData(field);
    };

    const addSuccessMessage = (message) => {
        toast.success(message);
    }
    const addErrorMessage = (message) => {
        toast.error(message);
    }
    return { getValue, getLabel, getData, getAll, getRefData, getScriptValue, addSuccessMessage, addErrorMessage }
}

export default useGForm;