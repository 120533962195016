import RelatedListTab from './RelatedListTab';
import { useState, useEffect } from 'react';
import { findColumnData } from '../services/helper';

const RelatedList = ({ relatedLists, current }) => {

    //console.log(relatedLists);

    const [activeTab, setActiveTab] = useState(relatedLists[0]);
    const [queryWith, setQueryWith] = useState([{ column: '', operator: '', value: '' }]);

    useEffect(() => {
        if (relatedLists.length > 0 && activeTab.query_with !== undefined) {
            const tQueryWith = JSON.parse(activeTab.query_with);
            const tValue = findColumnData(current, tQueryWith[0]['parent']);
            const list = [...queryWith];
            list[0]['column'] = tQueryWith[0]['child'];
            list[0]['operator'] = "EQUALS";
            list[0]['value'] = tValue;
            setQueryWith(list);
            console.log(list);
        }
    }, [relatedLists, current])

    const handleTabClick = (tab) => {
        if (relatedLists.length > 0 && tab && tab.query_with !== undefined) {
            const tQueryWith = JSON.parse(tab.query_with);
            const tValue = findColumnData(current, tQueryWith[0]['parent']);
            const list = [...queryWith];
            list[0]['column'] = tQueryWith[0]['child'];
            list[0]['operator'] = "EQUALS";
            list[0]['value'] = tValue;
            setQueryWith(list);
        }
        setActiveTab(tab);
    };

    return (
        <div>
            {relatedLists.length > 0 ?
                <div className="tab-container">
                    <ul className="nav nav-tabs">
                        {relatedLists.map((tab) =>
                            <li key={tab.id} className="nav-item">
                                <a className={`nav-link ${tab === activeTab ? 'active' : ''}`}
                                    onClick={() => handleTabClick(tab)}>
                                    {tab.title}
                                </a>
                            </li>
                        )}
                    </ul>
                    <div className="tab-content">
                        {activeTab && current && queryWith[0]['column'] !== '' &&
                            <div className="m-1">
                                <RelatedListTab table={activeTab.ref_table_id} queryWith={queryWith} current={current} />
                            </div>
                        }
                    </div>
                </div>
                : null}
        </div>
    );
}

export default RelatedList;