import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import gs from '../../services/gs';

const AuthLogin = () => {
    let navigate = useNavigate();
    const usernameRef = useRef(null);

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");

    useEffect(() => {
        const isTokenAvailable = localStorage.getItem('token');
        if (isTokenAvailable)
            navigate("/auxin");
        if (usernameRef.current) {
            usernameRef.current.focus();
          }
    }, [navigate]);

    const validateUsername = () => {
        return username.length >= 5 && username.length <= 15;
    }

    const validatePassword = () => {
        return password.length >= 5 && password.length <= 15;
    }

    const handleLogin = (e) => {
        e.preventDefault();

        setMessage("");
        setLoading(true);

        if (!validateUsername()) {
            setMessage("Username should be between 5 to 15");
            setLoading(false);
            return;
        }

        if (!validatePassword()) {
            setMessage("Password should be between 5 to 15");
            setLoading(false);
            return;
        }

        gs.getToken(username, password).then(
            (res) => {
                const {token } = res;
                setLoading(false);
                localStorage.setItem('token', token)
                navigate("/auxin");
                //window.location.reload();
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                setLoading(false);
                setMessage(resMessage);
            }
        );
    };

    return (
        <div className='login-container'>
            <form style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} onSubmit={handleLogin}>
                <div className='login-text'>Login</div>
                <div className="form-group">
                    <label className="control-label" >Username</label>
                    <div className="form-field input_controls">
                        <input type="text" id='username' ref={usernameRef} tabIndex="1" className="form-control" onChange={(e) => setUsername(e.target.value)} />
                    </div>
                </div>
                <div className="form-group">
                    <label className="control-label" >Username</label>
                    <div className="form-field input_controls">
                        <input type="password" id='password' tabIndex="2" className="form-control" onChange={(e) => setPassword(e.target.value)} />
                    </div>
                </div>

                <div className="form-group" style={{ marginLeft: "auto" }}>
                    <button className="btn btn-primary btn-block" disabled={loading}>
                        {loading && (
                            <span className="spinner-border spinner-border-sm"></span>
                        )}
                        <span>Login</span>
                    </button>
                </div>
                {message && <p className='login-error'>{message}</p>}
            </form>
        </div>
    )
}

export default AuthLogin;
