import { Route, Routes } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import './App.css';
import Layout from './components/LayoutAuxin';
import Home from './routes/Home';
import ListPage from './routes/ListPage';
import FormView from './components/FormView';
import SysPropertyView from "./routes/SysPropertyView";
import BillView from "./components/billing";
import ScriptPad from "./routes/ScriptPad";
import Page from "./routes/Page";
import AuthWrapper from "./pages/authentication/AuthWrapper";
import Logout from "./pages/authentication/Logout";
import LayoutAuxin from "./components/LayoutAuxin";
import LayoutPortal from "./components/portal/LayoutPortal";

//https://github.com/sangammukherjee/25-reactjs-interview-projects/tree/master
//https://www.youtube.com/watch?v=5ZdHfJVAY-s&t=6410s
function App() {
  return (
    <div className="App">
      <ToastContainer position='bottom-center' />
      <Routes>
        <Route path="/" element={<LayoutPortal />}>
          <Route index element={<Page />} />
          <Route path="login" element={<AuthWrapper />} />
          <Route path="logout" element={<Logout />} />
        </Route>
      </Routes>
      <Routes>
        <Route path="/auxin" element={<LayoutAuxin />}>
          <Route path=":page" element={<Page />} />
          <Route path="list/:paramTable" element={<ListPage />} />
          <Route path="form/:paramTable/:paramId" element={<FormView />} />
          <Route path="bill/:paramId" element={<BillView />} />
          <Route path="property/:prefix" element={<SysPropertyView />} />
          <Route path="scriptpad" element={<ScriptPad />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
