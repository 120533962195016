import React from 'react'

function AuthFooter() {
    return (
        <div className='auth-footer-container'>
            <div>&copy; Appa Automation</div>
            <div>
                <div>Privacy Policy</div>
                <div>Support</div>
            </div>
        </div>
    )
}

export default AuthFooter